<template>
  <div ref="stSelect" class="st-select" :class="wrapperClasses" :data-t="dataT">
    <Select
      :placeholder="placeholder"
      :label="label"
      :icon-prefix="selectedItem?.icon || iconPrefix"
      :icon-postfix="isExpanded ? 'chevron-top' : 'chevron-down'"
      :first-value="selectedItem?.title"
      :second-value="selectedItem?.subtitle"
      :third-value="selectedItem?.value"
      :size="selectSize"
      :platform="platform"
      :is-native="isNative"
      @click="toggleSelect(!isExpanded)"
    />
    <template v-if="isNative">
      <select
        v-model="vModelValue"
        class="native-select"
        data-t="native-select-4zo0"
      >
        <option
          v-for="option in items"
          :key="option.id"
          :value="option.id"
          :data-t="`value-i86h-${option.id}`"
        >
          {{ option.title }}
        </option>
      </select>
    </template>
    <template v-else>
      <Expanded
        v-if="platform === 'desktop'"
        :items="items"
        :size="size"
        :selected-value="modelValue"
        :is-expanded="isExpanded"
        :action-button-prefix-icon="actionButtonPrefixIcon"
        :action-button-postfix-icon="actionButtonPostfixIcon"
        :action-button-text="actionButtonText"
        @handle-dropdown-click="emit('handleDropdownClick')"
        @handle-option-clicked="handleOptionClicked"
      >
        <template #options-title>
          <slot name="options-title" />
        </template>
        <template #empty-list>
          <slot name="empty-list"></slot>
        </template>
      </Expanded>
      <StDrawer
        v-if="platform === 'mobile'"
        v-model="isExpanded"
        :full-screen="false"
      >
        <slot name="options-title" :toggle-select="toggleSelect" />
        <slot v-if="!items.length" name="empty-list"></slot>
        <div :class="{ 'with-scroll': isScrollMobile }" class="options-wrapper">
          <Option
            v-for="item in items"
            :key="item.id"
            :item="item"
            :size="size"
            :platform="platform"
            :is-selected="modelValue === item.id"
            @option-clicked="handleOptionClicked"
          />
        </div>
        <div
          v-if="actionButtonText"
          class="actions"
          data-t="actions"
          @click="emit('handleDropdownClick')"
        >
          <StIcon
            v-if="actionButtonPrefixIcon"
            :name="actionButtonPrefixIcon"
          />
          {{ actionButtonText }}
          <StIcon
            v-if="actionButtonPostfixIcon"
            :name="actionButtonPostfixIcon"
          />
        </div>
      </StDrawer>
    </template>
  </div>
</template>

<script setup lang="ts">
import { onClickOutside } from '@st/use/composables'
import type { SelectProps } from './types'
import Select from './parts/Select.vue'
import Option from './parts/Option.vue'
import Expanded from './parts/Expanded.vue'

const emit = defineEmits<{
  (e: 'change', value: number): void
  (e: 'handleDropdownClick'): void
  (e: 'update:modelValue', value: number | string): void
}>()

const props = withDefaults(defineProps<SelectProps>(), {
  size: 'm',
  dataT: 'st-select',
  platform: 'desktop',
  isNative: false,
  isScrollMobile: false,
})

const vModelValue = computed({
  get() {
    return props.modelValue
  },
  set(newValue: string | number) {
    emit('update:modelValue', newValue)
  },
})

const wrapperClasses = computed(() => `size-${props.size}`)

const isExpanded = ref(false)

const stSelect = ref<HTMLDivElement>()
onClickOutside(stSelect, () => {
  if (props.platform === 'desktop') {
    isExpanded.value = false
  }
})

function toggleSelect(value: boolean) {
  isExpanded.value = value
}

const selectedItem = computed(() =>
  props.items.find((item) => item.id === props.modelValue && !item.disabled),
)

function handleOptionClicked(id: number | string) {
  const selected = props.items.find((item) => item.id === id)

  if (selected?.disabled) return

  toggleSelect(false)

  emit('update:modelValue', id)
}

const selectSize = computed(() => {
  if (props.platform === 'mobile') {
    return 'm'
  }

  return props.size
})
</script>

<style scoped>
.st-select {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.actions {
  cursor: pointer;

  display: flex;
  gap: var(--spacing-100);
  align-items: center;
  justify-content: center;

  padding: var(--spacing-100) 0;

  font: var(--desktop-text-sm-semibold);
  color: var(--button-primary-default);
}

.options-wrapper {
  padding-bottom: var(--spacing-300);
}

.native-select {
  position: absolute;
  inset: 0;
  opacity: 0;
}

.with-scroll {
  overflow-x: auto;
}
</style>
